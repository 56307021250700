import React from 'react';
import './Portfolio.css';
import profileImage from '../../Assets/Profile/Profile.PNG';
import Github from '../../Assets/Logos/github.png'
import LinkedIn from '../../Assets/Logos/linkedin.png'

const Content = () => {
  const skillPortfolioRef = React.useRef(null);
  return (
    <div className="content" ref={skillPortfolioRef}>
      <div className="intro">
        <h2>Hi, I am</h2>
        <h1>Harshawardhan</h1>
        <p> Front-end Developer / Data Scientist </p>
        <div className="social-buttons">
          <a href="https://github.com/HarshawardhanVijayan" target="_blank" rel="noopener noreferrer" className="btn github-btn">
          <img alt='icon' className="Icon" src={Github} />
          </a>
          <a href="https://www.linkedin.com/in/harsha-vijayan/" target="_blank" rel="noopener noreferrer" className="btn linkedin-btn">
            <img alt='icon' className='Icon' src={LinkedIn} />
          </a>
        </div>
      </div>
      <div className="profile-pic">
        <img src={profileImage} alt="Profile" />
      </div>
    </div>
  );
};

export default Content;

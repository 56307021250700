import React from 'react';
import gitIcon from '../../Assets/Logos/git.svg';
import reactjsIcon from '../../Assets/Logos/reactjs.svg';
import nodejsIcon from '../../Assets/Logos/nodejs.svg';
import bootstrapIcon from '../../Assets/Logos/bootstrap.svg';
import pythonIcon from '../../Assets/Logos/python.svg';
import javaIcon from '../../Assets/Logos/java.svg';
import cplusplusIcon from '../../Assets/Logos/cplusplus.svg';
import tableauIcon from '../../Assets/Logos/tableau.svg';
import RstudioIcon from '../../Assets/Logos/RStudio.png'
import '../SkillList/SkillList.css'

const icons = {
  git: gitIcon,
  reactjs: reactjsIcon,
  nodejs: nodejsIcon,
  bootstrap: bootstrapIcon,
  python: pythonIcon,
  java: javaIcon,
  cplusplus: cplusplusIcon,
  tableau: tableauIcon,
  Rstudio: RstudioIcon
};

const SkillCard = ({ title, icon, description }) => {
  return (
    <div className="skill-card">
      <img src={icons[icon]} alt={title} className="skill-icon" />
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};

export default SkillCard;

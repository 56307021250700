import React from 'react';
import './Navbar.css';

const Navbar = ({ handleScrollToSkills, handleScrollToPortfolio, handleScrollToContact, handleScrollToAboutMe }) => {
  return (
    <div className='navbar'>
      <div className="nav-links">
        <ul>
        <li><p onClick={handleScrollToPortfolio}>Home</p></li>
          <li><p onClick={handleScrollToAboutMe}>About me</p></li>
          <li><p onClick={handleScrollToSkills}>Skills</p></li>
        </ul>
        <p onClick={handleScrollToContact} className="btn btn-secondary">Contact me</p>
      </div>
    </div>
  );
};

export default Navbar;

import React from 'react';
import './AboutMe.css';

const AboutMe = () => {
  return (
    <div className="about-me-container">
      <header className="about-me-header">
        <h1>About Me</h1>
      </header>
      <main className="about-me-content">
        <section className="about-me-section design">
          <h2>Design</h2>
          <p>
            I can design the site based on your needs and suggestions. I can also design the site from scratch and consult you during the job.
          </p>
        </section>
        <section className="about-me-section development">
          <h2>Development</h2>
          <p>
            I specialize in creating seamless digital experiences where design and development merge harmoniously. With a focus on React and CSS, I craft user-friendly interfaces that are both visually appealing and highly functional.
          </p>
        </section>
        <section className="about-me-section maintenance">
          <h2>Maintenance</h2>
          <p>
            Beyond initial development, I prioritize maintenance like the solid base of a triangle,
            ensuring your applications remain robust and reliable. I provide ongoing support, updates,
            and optimization to keep everything running smoothly.
          </p>
        </section>
      </main>
    </div>
  );
};

export default AboutMe;
import React, { useRef } from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Portfolio from './components/Portfolio/Portfolio';
import SkillList from './components/SkillList/SkillList';
import ContactPage from './components/Contact/ContactPage';
import AboutMe from './components/AboutMe/AboutMe';

function App() {
  const skillListRef = useRef(null);
  const portfolioRef = useRef(null);
  const contactRef = useRef(null);
  const aboutMeRef = useRef(null); // Correctly named ref

  const handleScrollTo = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="Main">
      <Navbar 
        handleScrollToSkills={() => handleScrollTo(skillListRef)} 
        handleScrollToPortfolio={() => handleScrollTo(portfolioRef)}
        handleScrollToContact={() => handleScrollTo(contactRef)}
        handleScrollToAboutMe={() => handleScrollTo(aboutMeRef)} // Correctly named prop
      />
      <div ref={portfolioRef} className='Portfolio-section'>
        <Portfolio />
      </div>
      <div ref={aboutMeRef} className='aboutme-section'> {/* Attach ref here */}
        <AboutMe />
      </div>
      <div ref={skillListRef} className='skills-section'>
        <SkillList />
      </div>
      <div ref={contactRef} className='contact-section'>
        <ContactPage />
      </div>
    </div>
  );
}

export default App;
